import React, { useContext, useEffect, useState } from "react";
import { motion } from "framer-motion";
import Coin from "../../../components/SmallComponents/Coin/Coin";
import { StoreContext } from "../../../context/StoreContext";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-hot-toast";
const Challenge = ({
  challenge,
  setAcceptModal,
  setAcceptedChallengeId,
  setAcceptedAmount,
  getChallenges,
  setShowConfirmModal,
  cancelChallenge,
  setCancelingId,
}) => {
  const { userData, serverUrl,getProfilePicUrl } = useContext(StoreContext);
  const navigate = useNavigate();
  return (
    <motion.div
      className="border border-[#d40007]/70 rounded-lg p-2 mb-3 bg-black"
      whileInView={{ scale: 1.05, opacity: 1 }}
      initial={{ scale: 0.9, opacity: 0 }}
      transition={{ duration: 0.5 }}
      viewport={{ once: false }}
    >
      <div
        onClick={() => {
          if (challenge.acceptedBy?.gameName && challenge.status != "ended") {
            navigate(`${challenge._id}`);
            
          }
          console.log(challenge)
        }}
        className="grid grid-cols-3"
      >
        <div className="flex flex-col items-center overflow-hidden">
          <div className="border-2 border-red-600 p-1 rounded-full overflow-hidden w-fit">
            {challenge.createdBy && <img
                className="w-[45px] h-[45px] rounded-full"
                src={getProfilePicUrl(challenge.createdBy.user)}
                alt="Avatar"
              />}
          </div>
          <span className="text-sm line-clamp-1">
            {challenge.createdBy?.user?.fullname ||
              challenge.createdBy?.gameName}
          </span>
        </div>
        <div className="flex flex-col items-center text-white">
          <p className="text-orange-700 font-semibold text-xs">
            Ludo-{challenge._id.toString().slice(-5)}
          </p>
          <p className="text-green-700 font-semibold text-xs">
            Ludo King Classic
          </p>
          {challenge.status == "created" && (
            <p className="text-white text-xs capitalize">Challenged</p>
          )}
          {challenge.status == "active" && (
            <p className="text-white text-xs capitalize">Accepted</p>
          )}
          {challenge.status == "start" && (
            <p className="text-white text-xs capitalize">Started</p>
          )}
          {challenge.status == "ended" && (
            <p className="text-white text-xs capitalize">Ended</p>
          )}
          {challenge.status == "pending" && (
            <p className="text-white text-xs capitalize">Result Pending</p>
          )}
          <div className="flex items-center gap-1">
            <Coin />
            {challenge.amount}
          </div>
        </div>

        {challenge.acceptedBy?.user?.fullname ? (
          <div className="flex flex-col items-center overflow-hidden">
            <div className="border-2 border-[#d40007] p-1 rounded-full overflow-hidden w-fit">
              {challenge.acceptedBy && (
                <img
                  className="w-[45px] h-[45px] rounded-full"
                  src={getProfilePicUrl(challenge.acceptedBy?.user)}
                  alt="Avatar"
                />
              )}
            </div>
            <span className="text-sm line-clamp-1">
              {challenge.acceptedBy?.user?.fullname ||
                challenge.acceptedBy?.gameName}
            </span>
          </div>
        ) : (
          <div className="flex flex-col items-center">
            <div className="border-2 border-[#d40007] p-1 rounded-full overflow-hidden w-fit">
              <div className="w-[45px] h-[45px] flex gap-1 flex-col rounded-full overflow-hidden">
                <div className="border border-[#d40007]"></div>
                <div className="border border-[#d40007]"></div>
                <div className="border border-[#d40007]"></div>
                <div className="border border-[#d40007]"></div>
                <div className="border border-[#d40007]"></div>
                <div className="border border-[#d40007]"></div>
                <div className="border border-[#d40007]"></div>
                <div className="border border-[#d40007]"></div>
                <div className="border border-[#d40007]"></div>
                <div className="border border-[#d40007]"></div>
              </div>
            </div>
            <span className="text-sm">Waiting...</span>
          </div>
        )}
      </div>
      {challenge.status != "ended" ? (
        <div
          className={`grid  ${
            userData &&
            challenge.status != "start" &&
            (userData._id == challenge.createdBy.user._id ||
              userData._id == challenge.acceptedBy?.user?._id)
              ? "grid-cols-3"
              : "grid-cols-2"
          } gap-2 w-full mt-2 text-sm font-semibold`}
        >
          {challenge.acceptedBy?.gameName ? (
            <Link
              to={`${challenge._id}`}
              className="bg-green-700 rounded-full py-2 text-center cursor-pointer"
            >
              Open
            </Link>
          ) : (
            <div className="bg-green-700 rounded-full flex items-center justify-center gap-2 py-2">
              <span>Win:</span>
              <div className="flex items-center gap-1">
                <Coin />
                {challenge.winAmount}
              </div>
            </div>
          )}
          {userData &&
            (userData._id === challenge.createdBy.user?._id ||
              userData._id === challenge.acceptedBy.user?._id) &&
            challenge.status != "start" && (
              <div
                onClick={() => {
                  setCancelingId(challenge._id);
                  setShowConfirmModal(true);
                }}
                className="bg-[#d40007] rounded-full flex items-center justify-center gap-2 py-2 cursor-pointer"
              >
                Cancel
              </div>
            )}

          {userData && userData._id == challenge.createdBy.user._id ? (
            <>
              {challenge.acceptedBy?.gameName ? (
                <div className="bg-green-700 rounded-full py-2 text-center cursor-pointer">
                  Accepted
                </div>
              ) : (
                <div className="bg-blue-700 rounded-full py-2 text-center cursor-pointer">
                  waiting...
                </div>
              )}
            </>
          ) : (
            <>
              {challenge.acceptedBy?.gameName ? (
                <div className="bg-green-700 rounded-full py-2 text-center cursor-pointer">
                  Accepted
                </div>
              ) : (
                <div
                  onClick={() => {
                    setAcceptModal(true);
                    setAcceptedChallengeId(challenge._id);
                    setAcceptedAmount(challenge.amount);
                  }}
                  className="bg-blue-700 rounded-full py-2 text-center cursor-pointer"
                >
                  Accept
                </div>
              )}
            </>
          )}
        </div>
      ) : (
        <div className={`text-md w-full mt-2 text-center`}>
          {challenge.createdBy.user._id==userData._id&&`You ${challenge.createdBy.stats} the challenge.`}
          {challenge.acceptedBy.user._id==userData._id&&`You ${challenge.acceptedBy.stats} the challenge.`}
        </div>
      )}
    </motion.div>
  );
};

export default Challenge;
