import React, { useState } from "react";
import { Link } from "react-router-dom";

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const faqs = [
    {
      question: "How to install WarPoint on the home screen?",
      answer:
        "Open WarPoint in your Chrome browser, click on the three dots in the top-right corner, and select 'Add to Home Screen'. Confirm the installation to add it to your home screen.",
    },
    {
      question: "How to join a match in Solo?",
      answer:
        "Navigate to the 'Matches' section, select a Solo match, and click 'Join'. Ensure you have sufficient balance before joining.",
    },
    {
      question: "How to select a slot and join a match in Squad/Duo?",
      answer:
        "Go to the 'Matches' section, choose a Squad or Duo match, and select your preferred slot. After selecting the slot, click 'Submit' to confirm your participation.",
    },
    {
      question: "How to add money?",
      answer:
        "Go to the 'Wallet' section, enter the amount you want to add, and follow the instructions to complete the transaction.",
    },
    {
      question: "How to withdraw money?",
      answer:
        "In the 'Wallet' section, select 'Withdraw', enter the amount you wish to withdraw, and confirm. The withdrawal will be processed based on your selected method.",
    },
    {
      question: "How to change avatar?",
      answer:
        "Go to your profile, click on your current avatar, and upload a new image from your device to update your avatar.",
    },
    {
      question: "How to update name?",
      answer:
        "In your profile settings, click 'Update Name', enter your new name, and save the changes to update your profile.",
    },
    {
      question: "How to update game ID and names?",
      answer:
        "Navigate to your profile, find the 'Game IDs' section, and click 'Edit'. Enter your new game ID and name, then save the changes.",
    },
  ];

  const toggleFAQ = (index) => {
    // Set the active index to the clicked index, or null if it's already active
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="bg-black text-white px-4 py-10 flex justify-center">
      <div className="max-w-[1300px] w-full">
        <h1 className="text-2xl font-bold text-[#d40007] mb-8 text-center">
          Frequently Asked Questions
        </h1>
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
          {faqs.map((faq, index) => (
            <div key={index} className="border-b border-[#d40007]/40 pb-4">
              <button
                className="flex justify-between items-center w-full text-left focus:outline-none"
                onClick={() => toggleFAQ(index)}
              >
                <h2 className="text-lg font-semibold">{faq.question}</h2>
                <span className="text-2xl">
                  {activeIndex === index ? "-" : "+"}
                </span>
              </button>
              <ul
                className={`mt-2 text-xs gap-1 pl-3 flex-col ${
                  activeIndex === index ? "flex" : "hidden"
                }`}
              >
                {faq.answer.split(",").map((step, stepIndex) => {
                  return (
                    <li key={stepIndex}>
                      <span className="pr-1">{stepIndex + 1}.</span>
                      <span className="capitalize">{step}</span>
                    </li>
                  );
                })}
                {index === 0 && (
                  <Link
                    to={`/installguide`}
                    className="bg-[#d40007] p-2 text-center capitalize"
                  >
                    Open full guide
                  </Link>
                )}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQ;
