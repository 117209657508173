import React, { useContext, useEffect} from "react";
import gsap from "gsap";
import { Link } from "react-router-dom";
import { useGSAP } from "@gsap/react";
import { StoreContext } from "../../context/StoreContext";

const Home = () => {
  const { setCurrTab } = useContext(StoreContext);
  useGSAP(() => {
    const tl = gsap.timeline();
  
    tl.to(
      ".char1",
      {
        opacity: 1,
        stagger: 0.05,
        duration: 1,
        ease: "power1.inOut",
      },
      "start"
    )
    .to(
      ".char2",
      {
        opacity: 1,
        stagger: 0.2,
        duration: 1.5,
        ease: "power1.inOut",
      },
      "start"
    )
    .to(
      ".char3",
      {
        opacity: 1,
        stagger: 0.03,
        duration: 1,
        ease: "power1.inOut", 
      },
      "start"
    )
    .to(
      ".popupbutton",
      {
        opacity: 1,
        duration: 1,
        ease: "power1.inOut",
      },
      "start"
    );
  });
  
  useEffect(() => {
    setCurrTab("home");
  }, []);
  return (
    <div className="h-screen w-screen max-[700px]:h-fit max-[700px]:pt-16  bg-cover   overflow-hidden relative">
      <div className="bg-stars max-[700px]:opacity-[0.4]">
        <span className="star"></span>
        <span className="star"></span>
        <span className="star"></span>
        <span className="star"></span>
      </div>
      <div className="w-full h-full bg-black max-[700px]:py-8">
        <div className="h-full w-full flex items-center justify-center flex-col">
          <h2 className="text-white text-[5vw] font-semibold leading-none tracking-tighter overflow-hidden flex">
            {"Battle for Glory at".split("").map((char, index) => {
              return char == " " ? (
                <span key={index} className="char1 opacity-0">
                  &nbsp;
                </span>
              ) : (
                <span key={index} className="char1 opacity-0">
                  {char}
                </span>
              );
            })}
          </h2>
          <h1 className="text-white text-[25vw] font-anton  leading-none  uppercase flex overflow-hidden">
            {" "}
            {"warpoint".split("").map((char, index) => {
              return char == " " ? (
                <span key={index} className="char2 opacity-0">
                  &nbsp;
                </span>
              ) : (
                <span key={index} className="char2 charGradianBg opacity-0">
                  {char}
                </span>
              );
            })}
          </h1>
          <p className="text-white flex overflow-hidden">
            {"Your Premier Tournament Destination"
              .split("")
              .map((char, index) => {
                return char == " " ? (
                  <span key={index} className="char3 opacity-0">
                    &nbsp;
                  </span>
                ) : (
                  <span key={index} className="char3 opacity-0">
                    {char}
                  </span>
                );
              })}
          </p>
          <Link
            to={"/alltournaments"}
            type="button"
            className="popupbutton opacity-0 spacebtn mt-3"
          >
            <strong className="uppercase">tournaments</strong>
            <div id="container-stars">
              <div id="stars"></div>
            </div>

            <div id="glow">
              <div className="circle"></div>
              <div className="circle"></div>
            </div>
          </Link>
          
        </div>
      </div>
    </div>
  );
};

export default Home;
