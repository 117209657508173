import React, { useContext, useRef } from "react";
import { StoreContext } from "../../context/StoreContext.jsx";
import Avatar from "./Avatar.jsx";
const AvatarScroll = () => {
  const { avatarBackground, avatars, avtarUrl, premiumAvatars } =
    useContext(StoreContext);
  // Concatenating multiple backgrounds for animation purposes
  const bgs = [
    ...avatarBackground,
    ...avatarBackground,
    ...avatarBackground,
    ...avatarBackground,
  ];
  // Function to render avatars
  const renderAvatars = (avatarList, url, isPremium = false) => {
    return avatarList.slice(0, 10).map((av, index) => (
      <Avatar
        key={index}
        avatar={av}
        url={url}
        isPremium={isPremium}
        background={bgs[index]}
      />
    ));
  };

  return (
    <div className="bg-black text-red-500 overflow-hidden py-5 flex flex-col gap-2 max-w-[1800px]">
      <div className="flex flex-col gap-3 relative">
        <div className="absolute h-full w-full max-w-[150px] lg:bg-gradient-to-r lg:from-black  lg:to-transparent z-[2]"></div>
        <div className="absolute right-0 h-full w-full max-w-[150px] lg:bg-gradient-to-l lg:from-black  lg:to-transparent z-[2]"></div>
        <div className="absolute top-0 font-bold font-anton text-[#d40007] w-full flex items-center justify-center  h-[200px]  text-[30vw] md:text-[20vw] lg:text-[10vw]">
          <span className="charGradianBg">A</span>
          <span className="charGradianBg">V</span>
          <span className="charGradianBg">A</span>
          <span className="charGradianBg">T</span>
          <span className="charGradianBg">A</span>
          <span className="charGradianBg">R</span>
          <span className="charGradianBg">S</span>
        </div>
        {/* Regular avatars */}
        <div className="flex gap-3">
          <div className="flex animate-scroll gap-3">
            {renderAvatars(avatars, avtarUrl)}
          </div>
          <div className="flex animate-scroll gap-3">
            {renderAvatars(avatars, avtarUrl)}
          </div>
          <div className="flex animate-scroll gap-3">
            {renderAvatars(avatars, avtarUrl)}
          </div>
        </div>
        <div className="flex gap-3">
          <div className="flex reverse-scroll gap-3">
            {renderAvatars(premiumAvatars, "", true)}
          </div>
          <div className="flex reverse-scroll gap-3">
            {renderAvatars(premiumAvatars, "", true)}
          </div>
          <div className="flex reverse-scroll gap-3">
            {renderAvatars(premiumAvatars, "", true)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AvatarScroll;
