import React, { useContext, useEffect, useState } from "react";
import NavLinkBtn from "./SmallComponents/NavLinkBtn";
import { Link, useNavigate } from "react-router-dom";
import { StoreContext } from "../context/StoreContext";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import MenuItem from "./SmallComponents/MenuItem";
import NavLogo from "./SmallComponents/NavLogo";
const Navbar = () => {
  const { token } = useContext(StoreContext);
  const [menu, setMenu] = useState(false);
  const [num, setNum] = useState(0);
  const [animating, setAnimating] = useState(false);
  const navigate = useNavigate();
  // useGSAP(() => {
  //   const tl = gsap.timeline().to(".circlelogo", {
  //     x: 0,
  //     duration: 1,
  //   });
  // });
  const handleMenuClick = () => {
    setAnimating(true); // Start animating immediately
  
    const tl = gsap.timeline({
      onComplete: () => setAnimating(false),
      defaults: {
        duration: 0.5, // Set a default duration for all animations
        ease: "power1.inOut", // Use easing for smooth transitions
      },
    });
  
    if (menu) {
      tl.to(".menu", {
        autoAlpha: 1,
      })
      .to(
        ".menu",
        {
          opacity: 1,
        },
        0 // Start this animation simultaneously with the previous one
      )
      .to(
        ".menuchar",
        {
          y: 0,
          stagger: 0.1, // Add stagger for a cascading effect on child elements
        },
        0 // Start this animation simultaneously as well
      );
    } else {
      tl.to(
        ".menuchar",
        {
          y: "100%",
          stagger: 0.1, // Stagger the exit animation as well
        },
        0 // Start this animation simultaneously
      )
      .to(".menu", {
        opacity: 0,
        duration: 0.3, // Shorten this duration for quicker exit
      })
      .to(".menu", {
        autoAlpha: 0,
        duration: 0.2, // A shorter duration for the final fade-out
      });
    }
  };
  
  useEffect(() => {
    handleMenuClick();
  }, [menu]);
  return (
    <div
      className={`fixed z-[999] backdrop-blur h-fit p-2 w-full text-white flex justify-between items-center`}
    >
      <NavLogo/>

      <div className="flex gap-2 ">
        <ul className="flex gap-2 items-center max-[500px]:hidden">
          <Link to={"/"}>
            <NavLinkBtn name={"home"} />
          </Link>

          {token != "" && (
            <Link to={`/profile/${token}`} className="">
              <NavLinkBtn name={"profile"} />
            </Link>
          )}
          {token == "" && (
            <Link to={"/login"} className="">
              <NavLinkBtn name={"login"} />
            </Link>
          )}
        </ul>
        <div
          onClick={() => {
            if (!animating) {
              menu ? setMenu(false) : setMenu(true);
            }
          }}
          className="flex flex-col items-center justify-center gap-1 relative z-[2] p-2"
        >
          <div
            className={`w-[25px] ${
              menu ? "rotate-[45deg] bg-[#d40007] border-[#d40007]":"bg-white"
            } border border-[2px] rounded-full relative z-[2] duration-500`}
          ></div>
          <div
            className={`w-[30px] ${
              menu ? "rotate-[130deg] bg-[#d40007] border-[#d40007]":"bg-white"
            } border border-[2px] rounded-full relative z-[2] duration-500`}
          ></div>
        </div>
      </div>
      <div className="menu w-screen opacity-0 flex items-center justify-center h-screen bg-black/90 gap-2 absolute top-0 left-0 overflow-hidden z-[1]">
        <ul className="flex flex-col items-center text-[3vw] max-[1024px]:text-[6vw] max-[600px]:text-[9vw] max-[400px]:text-[10vw] justify-center uppercase font-semibold">
          <MenuItem text="home" navigateTo="/" onClose={() => setMenu(false)} />

          {token !== "" && (
            <MenuItem
              text="profile"
              navigateTo={`/profile/${token}`}
              onClose={() => setMenu(false)}
            />
          )}

          {token === "" && (
            <>
              <MenuItem
                text="login"
                navigateTo="/login"
                onClose={() => setMenu(false)}
              />
              <MenuItem
                text="register"
                navigateTo="/register"
                onClose={() => setMenu(false)}
              />
            </>
          )}

          <MenuItem
            text="tournaments"
            navigateTo="/alltournaments"
            onClose={() => setMenu(false)}
          />
          <MenuItem
            text="leaderboard"
            navigateTo="/leaderboard"
            onClose={() => setMenu(false)}
          />
          <MenuItem
            text="About us"
            navigateTo="/aboutus"
            onClose={() => setMenu(false)}
          />
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
