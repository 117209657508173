import React from "react";
import { motion } from "framer-motion";
import Coin from "../../../components/SmallComponents/Coin/Coin";

const ChallengeModal = ({
  showModal,
  setShowModal,
  name,
  setName,
  amounts,
  amount,
  setAmount,
  error,
  handleCustomAmount,
  createChallenge,
}) => {
  const modalVariants = {
    hidden: {
      opacity: 0,
      scale: 0.8,
    },
    visible: {
      opacity: 1,
      scale: 1,
    },
    exit: {
      opacity: 0,
      scale: 0.8,
    },
  };

  return (
    <>
      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-80 flex justify-center items-center z-50">
          <motion.div
            className="bg-black border border-[#d40007] p-3 rounded-lg max-w-[400px] w-full relative"
            variants={modalVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            transition={{
              type: "spring",
              stiffness: 300,
              damping: 12,
            }}
          >
            <h3 className="font-semibold text-center capitalize text-sm mb-4 text-white">
              Add Ludo Challenge
            </h3>
            <input
              type="text"
              className="w-full p-2 mb-4 border border-[#d40007] rounded bg-gray-800 text-white"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Ludo Username"
            />
            <div className="grid grid-cols-4 gap-2 mb-4">
              {amounts.map((amt) => (
                <button
                  key={amt}
                  className={`p-2 flex items-center justify-center gap-1 rounded-full ${
                    amt === amount
                      ? "bg-[#d40007] text-white"
                      : "bg-gray-800 text-gray-300"
                  }`}
                  onClick={() => setAmount(amt)}
                >
                  <Coin />
                  {amt}
                </button>
              ))}
            </div>
            <input
              type="text"
              className="w-full p-2 mb-4 border border-red-600 rounded bg-gray-800 text-white"
              value={amount}
              onChange={handleCustomAmount}
              placeholder="Enter custom amount"
            />
            {error && (
              <p className="text-red-500 text-sm font-semibold mb-4">{error}</p>
            )}

            <div className="grid grid-cols-2 gap-3">
              <button
                className="bg-[#d40007] text-white py-2 px-4 rounded-full"
                onClick={() => setShowModal(false)}
              >
                Cancel
              </button>
              <button
                className="bg-green-700 text-white py-2 px-4 rounded-full"
                onClick={createChallenge}
              >
                Submit
              </button>
            </div>
          </motion.div>
        </div>
      )}
    </>
  );
};

export default ChallengeModal;
