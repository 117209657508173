import React, { useRef } from "react";
import { TbHomeFilled } from "react-icons/tb";
import { BiSolidUserCircle } from "react-icons/bi";
import { GiGamepadCross } from "react-icons/gi";
import { GiPodiumWinner } from "react-icons/gi";
import { FaQuestionCircle } from "react-icons/fa";
import { MdOutlineConnectWithoutContact } from "react-icons/md";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import { RiLoginCircleLine } from "react-icons/ri";
import { RiLogoutCircleLine } from "react-icons/ri";
import { GiArchiveRegister } from "react-icons/gi";
const NavLinkBtn = ({ name }) => {
  const link = useRef();
  const mouseEnterHandler = () => {
    gsap.to(link.current, {
      y: -30,
    });
  };
  const mouseLeaveHandler = () => {
    gsap.to(link.current, {
      y: 0,
    });
  };
  useGSAP(() => {});
  return (
    <li
      onMouseEnter={(e) => {
        mouseEnterHandler();
      }}
      onMouseLeave={() => {
        mouseLeaveHandler();
      }}
      className={`flex  items-center justify-center  rounded-full p-1`}
    >
      <span className="p-2 bg-[#d40007] rounded-full">
        {name == "home" && <TbHomeFilled />}
        {name == "profile" && <BiSolidUserCircle />}
        {name == "tournaments" && <GiGamepadCross />}
        {name == "leaderboard" && <GiPodiumWinner />}
        {name == "frequntly asked questions" && <FaQuestionCircle />}
        {name == "contact us" && <MdOutlineConnectWithoutContact />}
        {name == "login" && <RiLoginCircleLine />}
        {name == "register" && <GiArchiveRegister />}
        {name == "logout" && <RiLogoutCircleLine />}
      </span>
      <div className="flex flex-col h-[30px]  overflow-hidden  items-center">
        <div ref={link} className="">
          <div className="px-2 capitalize h-[30px]">{name}</div>
          <div className="px-2 capitalize h-[30px]">{name}</div>
        </div>
      </div>
    </li>
  );
};

export default NavLinkBtn;
