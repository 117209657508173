import React from "react";
import { Link } from "react-router-dom";
import {
  RiInstagramFill,
  IoLogoWhatsapp,
  FaEnvelope,
} from "./ReactIcons/Icons.js";
import { motion } from "framer-motion";
const Footer = () => {
  return (
    <div className="bg-black py-10 overflow-hidden">
      <div className="border-t border-b border-red-900/40  py-3 flex items-center justify-center overflow-hidden">
        <div className="flex gap-4 text-white">
          {[
            {
              href: "https://www.instagram.com/_warpoint_?igsh=MTIyMjR2dHo4Yng5MQ==",
              label: "Follow us on Instagram",
              icon: <RiInstagramFill />,
            },
            {
              href: "mailto:warpoint.help@gmail.com",
              label: "Email us",
              icon: <FaEnvelope />,
            },
            {
              href: "https://wa.me/916372881385",
              label: "Message us on WhatsApp",
              icon: <IoLogoWhatsapp />,
            },
          ].map((item, index) => (
            <motion.div
              key={index}
              whileHover={{
                scale: 1.1,
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
              }}
              transition={{ type: "spring", stiffness: 200, damping: 15 }}
            >
              <Link
                to={item.href}
                aria-label={item.label}
                className="bg-[#d40007]/80 shadow-lg w-12 h-12 rounded-lg flex items-center justify-center text-2xl transition-transform duration-150 ease-in-out hover:bg-[#d40007]"
              >
                {item.icon}
              </Link>
            </motion.div>
          ))}
        </div>
      </div>
      <div className="text-white flex items-center gap-5 flex-col justify-evenly py-10 overflow-hidden relative z-[2]">
        <h5 className="text-2xl font-semibold border-b w-fit ">WarPoint</h5>
        <div className="flex flex-col gap-3">
          <ul className="text-sm flex flex-wrap gap-3 flex items-center justify-center font-semibold uppercase">
            <li>
              <Link to={"/aboutus"}>About</Link>
            </li>
            <li>-</li>
            <li>
              <Link to={"/contact"}>Contact</Link>
            </li>
            <li>-</li>
            <li>
              <Link to={"/privacypolicy"}>Privacy</Link>
            </li>
          </ul>
          <div className="flex flex-col items-center gap-2">
            <p className="text-sm text-white/60 font-semibold capitalize">
              Website is maintained by{" "}
              <Link to={`https://github.com/skehargit`}>
                Sudhansu sekhar ↗️
              </Link>
              .
            </p>
            <p className="text-xs flex flex-wrap gap-2">
              &copy; Copyrights. All rights reserved.{" "}
              <a
                className="text-blue-800 font-semibold"
                href="https://www.warpoint.in"
              >
                www.warpoint.in
              </a>
            </p>
          </div>
        </div>
      </div>
      <div className="relative">
        <div className=" h-[5%] w-[100%] absolute bottom-0 left-0">
          <div className="wave"></div>
          <div className={`wave wave2 `}></div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
