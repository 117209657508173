import React, { Suspense, useContext, useEffect, useState } from "react";
import { Link, Route, Routes } from "react-router-dom";
import { Toaster, toast } from "react-hot-toast";
import { IoLogoWhatsapp } from "react-icons/io5";
import { StoreContext } from "./context/StoreContext.jsx";
import Loader from "./components/Loader.jsx";
import Navbar from "./components/Navbar.jsx";
import Footer from "./components/Footer.jsx";
import Layout from "./Layout.jsx";
import BottomNavBar from "./components/BottomNavBar.jsx";
import Ludo from "./pages/Ludo/Ludo.jsx";
import LudoDetails from "./pages/Ludo/LudoDetails.jsx";
import UploadScreenShot from "./pages/Ludo/UploadScreenShot.jsx";
// Lazy load components
const AllTournaments = React.lazy(() =>
  import("./pages/Tournaments/AllTournaments.jsx")
);
const UpdateName = React.lazy(() =>
  import("./pages/Name Update/UpdateName.jsx")
);
const UserProfile = React.lazy(() =>
  import("./pages/UserProfile/UserProfile.jsx")
);
const PageNotFound = React.lazy(() => import("./pages/Error/PageNotFound.jsx"));
const LeaderBoard = React.lazy(() =>
  import("./pages/LeaderBoard/LeaderBoard.jsx")
);
const Login = React.lazy(() => import("./pages/Login&Register/Login.jsx"));
const Register = React.lazy(() =>
  import("./pages/Login&Register/Register.jsx")
);
const Contact = React.lazy(() => import("./pages/Contact/Contact.jsx"));
const FAQ = React.lazy(() => import("./pages/FAQ/FAQ.jsx"));
const Tournament = React.lazy(() =>
  import("./pages/SpecificTournament/Tournament.jsx")
);
const Match = React.lazy(() => import("./pages/Match/Match.jsx"));
const Payment = React.lazy(() => import("./pages/Payment/Payment.jsx"));
const Avatars = React.lazy(() => import("./pages/Avtars/Avatars.jsx"));
const Show = React.lazy(() => import("./pages/GameNameId/Show.jsx"));
const Update = React.lazy(() => import("./pages/GameNameId/Update.jsx"));
const About = React.lazy(() => import("./pages/About/About.jsx"));
const PrivacyPolicy = React.lazy(() =>
  import("./pages/PrivacyPolicy/PrivacyPolicy.jsx")
);
const ForgotPassword = React.lazy(() =>
  import("./pages/ForgotPassword/ForgotPassword.jsx")
);
const BalancePage = React.lazy(() => import("./pages/Balance/Balance.jsx"));
const Results = React.lazy(() => import("./pages/Results/Results.jsx"));
const EmailVerification = React.lazy(() =>
  import("./pages/Verification/EmailVerification.jsx")
);
const OTPVerification = React.lazy(() =>
  import("./pages/Verification/OtpVerification.jsx")
);
const SlotSelector = React.lazy(() =>
  import("./pages/SlotPage/SlotSelector.jsx")
);
const MatchTransactions = React.lazy(() =>
  import("./pages/MatchTransactions/MatchTransactions.jsx")
);
const ViewParticipents = React.lazy(() =>
  import("./pages/Participents/ViewParticipents.jsx")
);
const InstallGuide = React.lazy(() =>
  import("./pages/InstallGuide/InstallGuide.jsx")
);
const ChangePassword = React.lazy(() =>
  import("./pages/ForgotPassword/ChangePassword.jsx")
);
// const BottomNavBar = React.lazy(() => import("./components/BottomNavBar.jsx"));
const Payments = React.lazy(() => import("./pages/Balance/Payments.jsx"));
const WithDrawl = React.lazy(() => import("./pages/Balance/WithDrawl.jsx"));
const TdmChallenge = React.lazy(() =>
  import("./pages/TdmChallenge/TdmChallenge.jsx")
);
const CreateChallenge = React.lazy(() =>
  import("./pages/TdmChallenge/CreateChallenge.jsx")
);
const InvitePage = React.lazy(() => import("./pages/Invite/InvitePage.jsx"));

function App() {
  const { currTab, token } = useContext(StoreContext);
  const [loading, setLoading] = useState(true);
  const loader = document.getElementById("preloader");

  if (loader) {
    setTimeout(() => {
      loader.style.display = "none";
      setLoading(false);
    }, 2500);
  }
  return (
    !loading && (
      <main className="bg-black" data-scroll-container>
        <Toaster position="top-center" reverseOrder={false} />
        <Suspense fallback={<Loader />}>
          <Navbar />
          <Link
            to={`https://wa.me/916372881385`}
            aria-label="Message us on whatsapp"
            className="bg-green-500 py-2 px-4 fixed z-[999] text-white right-0 bottom-24 rounded-l-full text-lg"
          >
            <IoLogoWhatsapp />
          </Link>
          <Routes>
            <Route path="/" element={<Layout />}></Route>
            <Route path="/alltournaments" element={<AllTournaments />}></Route>
            <Route path="alltournaments/:tag" element={<Tournament />}></Route>
            <Route
              path="alltournaments/:tag/:match"
              element={<Match />}
            ></Route>
            <Route
              path="alltournaments/:tag/:match/viewparticipents"
              element={<ViewParticipents />}
            ></Route>
            <Route
              path="alltournaments/:tag/:match/result"
              element={<Results />}
            ></Route>
            <Route
              path="alltournaments/:tag/:match/slotselector"
              element={<SlotSelector />}
            ></Route>
            <Route
              path="/profile/:tokenId/wallet/paymentgetaway"
              element={<Payment />}
            ></Route>
            <Route
              path="/profile/:tokenId/match/transactions"
              element={<MatchTransactions />}
            ></Route>
            <Route path="/profile/:tokenId" element={<UserProfile />}></Route>
            <Route
              path="/profile/:tokenId/wallet"
              element={<BalancePage />}
            ></Route>
            <Route
              path="/profile/:tokenId/updatename"
              element={<UpdateName />}
            ></Route>
            <Route
              path="/profile/:tokenId/wallet/payments"
              element={<Payments />}
            ></Route>
            <Route
              path="/profile/:tokenId/wallet/withdrawals"
              element={<WithDrawl />}
            ></Route>
            <Route
              path="/profile/:tokenId/invite"
              element={<InvitePage />}
            ></Route>
            <Route
              path="/profile/:tokenId/:userid/game-name-id/show"
              element={<Show />}
            ></Route>
            <Route
              path="/profile/:tokenId/:userid/game-name-id/show/update/:gamename"
              element={<Update />}
            ></Route>
            <Route
              path="/profile/:tokenId/avatars"
              element={<Avatars />}
            ></Route>
            <Route path="/leaderboard" element={<LeaderBoard />}></Route>
            <Route path="/login" element={<Login />}></Route>
            <Route path="/register" element={<Register />}></Route>
            <Route path="/register/:invitecode" element={<Register />}></Route>
            <Route
              path="/register/:fullname/email/verify"
              element={<EmailVerification />}
            ></Route>
            <Route
              path="/register/:fullname/email/verify/:email/number/verify"
              element={<OTPVerification />}
            ></Route>
            <Route path="/forgetpassword" element={<ForgotPassword />}></Route>
            <Route
              path="/forgetpassword/:token"
              element={<ChangePassword />}
            ></Route>
            <Route path="/ludo" element={<Ludo />}></Route>
            <Route path="/ludo/:id" element={<LudoDetails />}></Route>
            <Route path="/ludo/:id/screenshot" element={<UploadScreenShot />}></Route>
            <Route path="/installguide" element={<InstallGuide />}></Route>
            <Route path="/contact" element={<Contact />}></Route>
            <Route path="/faq" element={<FAQ />}></Route>
            <Route path="/aboutus" element={<About />}></Route>
            <Route path="/privacypolicy" element={<PrivacyPolicy />}></Route>
            <Route path="*" element={<PageNotFound />}></Route>
          </Routes>
          {currTab === "home" && <Footer />}
          <BottomNavBar />
        </Suspense>
      </main>
    )
  );
}

export default App;
