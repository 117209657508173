import React, { useContext, useEffect, useState } from "react";
import { StoreContext } from "../../context/StoreContext";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Coin from "../../components/SmallComponents/Coin/Coin";
import toast from "react-hot-toast";
import { RiMedal2Fill } from "react-icons/ri";
import { RiEmotionSadLine } from "react-icons/ri";
import { MdErrorOutline } from "react-icons/md";
import { FaRegCopy } from "react-icons/fa";
import Rules from "./Items/Rules";
import { IoLogoWhatsapp } from "../../components/ReactIcons/Icons.js";
// Main LudoDetails Component
const LudoDetails = () => {
  const {
    setCurrTab,
    serverUrl,
    userData,
    getProfilePicUrl,
    setUserData,
    socket,
  } = useContext(StoreContext);
  const [challenge, setChallenge] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false); // State to manage modal visibility
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    // Listen for 'newMatchCreated' event
    // socket.on("newMatchCreated", (data) => {
    //   console.log("New match created:", data);
    //   getChallenges();
    // });
    socket.on("refreshToChange", (data) => {
      console.log(data.message);
      // getChallenges();
      getChallenge();
    });
    // Cleanup listener on unmount
    return () => {
      // socket.off("newMatchCreated");
      socket.off("refreshToChange");
    };
  }, [socket]);
  useEffect(() => {
    if (!userData) {
      navigate("/login");
    }
  }, []);
  const [newRoomId, setNewRoomId] = useState(""); // State to hold the new room ID
  const { id } = useParams();
  const [lostModal, setLostModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const cancelChallenge = async (challengeId, idofuser) => {
    try {
      // API call to cancel the challenge
      const response = await axios.post(
        `${serverUrl}/ludo/cancel`,
        {
          challengeId,
          idofuser,
        },
        { withCredentials: true }
      );
      // If the challenge was canceled successfully
      if (response.data.success) {
        toast.success("Challenge canceled and deleted successfully!");
        navigate(-1);
        setShowConfirmModal();
      } else {
        // Handle the case where the cancelation failed
        toast.error(
          response.data?.message || "Failed to cancel the challenge."
        );
      }
    } catch (error) {
      // Handle any errors that occur during the API request
      toast.error(error.response?.data?.message || "Server Error!");
    } finally {
      setShowConfirmModal(false);
    }
  };
  // Function to copy Room ID to clipboard
  const copyToClipboard = async (roomId) => {
    try {
      await navigator.clipboard.writeText(roomId);
      toast.success("Room ID copied to clipboard!");
    } catch (error) {
      toast.error("Failed to copy Room ID.");
    }
  };

  // Function to fetch challenge data
  const getChallenge = async () => {
    try {
      const response = await axios.post(
        `${serverUrl}/ludo/getone`,
        { id },
        { withCredentials: true }
      );

      if (response.data.success) {
        setChallenge(response.data.challenge);
      } else {
        toast.error(response.data?.message || "Error!");
        navigate(-1);
      }
    } catch (error) {
      setChallenge({});
      toast.error(
        `Ludo-${id.toString().slice(-5)} challenge get canclled.` ||
          error.response?.data?.message ||
          "Error fetching challenge"
      );
      navigate(-1);
    }
  };

  // Set the current tab and fetch the challenge details when component mounts
  useEffect(() => {
    setCurrTab("ludodetails");
    getChallenge();
  }, [id, setCurrTab]);
  const challengeLost = async (challengeId) => {
    try {
      const response = await axios.post(
        `${serverUrl}/ludo/lost`,
        { challengeId },
        { withCredentials: true }
      );
      if (response.data.success) {
        toast.success("Challenge result updated successfully!");
        setChallenge(response.data.challenge);
      } else {
        toast.error(
          response.data.message || "Failed to update the challenge result."
        );
      }
    } catch (error) {
      toast.error(
        error.response?.data?.message ||
          error?.message ||
          "An error occurred while updating the challenge result."
      );
    } finally {
      setLostModal(false);
    }
  };

  // Function to handle room ID update
  const handleRoomIdUpdate = async () => {
    if (newRoomId.length !== 8 || !/^\d{8}$/.test(newRoomId)) {
      toast.error("Invalid room code!");
      return;
    }
    try {
      const response = await axios.post(
        `${serverUrl}/ludo/add`,
        { code: newRoomId, challengeId: id },
        { withCredentials: true }
      );

      if (response.data.success) {
        setChallenge(response.data.challenge);
        toast.success(`${response.data?.message || "Room Code Added"}`);
        setNewRoomId("");
        setIsModalOpen(false);
      } else {
        toast.error(response.data?.message || "Error updating room Code.");
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "Error updating room Code.");
    }
  };
  if (challenge && challenge.status == "ended") {
    navigate(-1);
    return;
  }
  return (
    <div className="min-h-screen py-16 px-5 flex justify-center bg-black">
      <div className="w-full text-white max-w-[500px]">
        <p className="text-orange-700 font-semibold text-sm mb-2">
          Ludo-{id.toString().slice(-5)}
        </p>
        <div className="border border-[#d40007]/70 rounded-lg p-4 mb-4 grid grid-cols-3 items-center">
          {/* Creator's Avatar and Name */}
          <UserInfo user={challenge.createdBy} />
          <div className="text-2xl font-semibold text-[#d40007] flex items-center justify-center">
            VS
          </div>
          {/* Accepted User's Avatar and Name */}
          <UserInfo user={challenge.acceptedBy} />
        </div>

        <div className="w-full grid grid-cols-2 gap-2 mb-4">
          <InfoCard
            label="Challenged"
            value={
              <div className="flex gap-1 items-center">
                <Coin />
                {challenge.amount}
              </div>
            }
          />
          <InfoCard
            label="Winning"
            value={
              <div className="flex gap-1 items-center">
                <Coin />
                {challenge.winAmount}
              </div>
            }
          />
        </div>

        <div
          onClick={() => {
            if (challenge.roomId) {
              copyToClipboard(challenge.roomId);
            }
          }}
          className="border border-[#d40007]/70 rounded-full flex gap-2 items-center justify-center text-sm py-1 mb-4 py-3 cursor-pointer"
        >
          Room Code: {challenge.roomId || "Update room code to proceed"}{" "}
          {challenge.roomId && <FaRegCopy />}
        </div>

        <div
          className={`w-full grid ${
            challenge.createdBy?.user?._id === userData._id
              ? "grid-cols-2"
              : "grid-cols-1"
          } gap-2 ${!challenge.roomId && "mb-4"}`}
        >
          {!challenge.roomId && (
            <ActionCard
              onClick={() => setShowConfirmModal(true)}
              label="Cancel Challenge"
            />
          )}
          {!challenge.roomId &&
            challenge.createdBy?.user?._id === userData._id && (
              <ActionCard
                label="Update Room Code"
                onClick={() => setIsModalOpen(true)}
              />
            )}
        </div>

        {challenge.status != "ended" ? (
          <div className="grid grid-cols-3 gap-2">
            <ActionCard
              onClick={() => navigate(`screenshot`, { state: { challenge } })}
              label="WON"
            />
            <ActionCard onClick={() => setLostModal(true)} label="LOST" />
            <ActionCard onClick={() => setErrorModal(true)} label="ERROR" />
          </div>
        ) : (
          <div className="grid grid-cols-1">
            <ActionCard label="Challenge Ended" />
          </div>
        )}

        <Rules />
      </div>

      {/* Modal for updating Room ID */}
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-70 z-50">
          <div className="bg-black border border-[#d40007]/70 rounded-lg p-4">
            <h3 className="text-white text-lg font-semibold mb-4">
              Update Room Code
            </h3>
            <input
              type="text"
              value={newRoomId}
              onChange={(e) => setNewRoomId(e.target.value)}
              placeholder="Enter new room code"
              className="w-full p-2 rounded bg-zinc-900 text-white mb-4"
            />
            <div className="flex flex-col gap-2 mb-3">
              <p className="max-w-[300px] w-full text-white text-xs">
                * If you enter the wrong room code by mistake, don't worry just
                cancel the challenge to receive a refund.
              </p>
              <p className="max-w-[300px] w-full text-white text-xs">
                * If you Lost, Update result clicking "Lost" within 1 hour or 20
                coins will be deducted.
              </p>
              <p className="max-w-[300px] w-full text-white text-xs">
                * If you win, Update result uploading a screenshot within 1 hour
                or 20 coins will be deducted.
              </p>
            </div>
            <div className="grid grid-cols-2 w-full gap-2">
              <button
                onClick={handleRoomIdUpdate}
                className="bg-green-600 hover:bg-green-500 text-white py-2 px-4 rounded"
              >
                Submit
              </button>
              <button
                onClick={() => setIsModalOpen(false)}
                className="bg-red-600 hover:bg-red-500 text-white py-2 px-4 rounded"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
      {showConfirmModal && (
        <div className="fixed inset-0 bg-black bg-opacity-80 flex justify-center items-center z-50 text-white">
          <div className="bg-black p-4 flex flex-col gap-2 border border-[#d40007]/60 rounded-lg">
            <p className=" text-sm">Do you want to cancel the match?</p>
            <p className="text-sm max-w-[400px]">
              Participants will receive refunds and the challenge will be
              deleted.
            </p>
            <div className="grid grid-cols-2 w-full gap-2">
              <button
                onClick={() => cancelChallenge(id, userData._id)}
                className="bg-[#d40007] text-white px-4 py-2 rounded"
              >
                Yes, Cancel
              </button>
              <button
                onClick={() => setShowConfirmModal(false)}
                className="bg-[#d40007] px-4 py-2 rounded"
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}
      {lostModal && (
        <div className="fixed inset-0 bg-black bg-opacity-80 flex justify-center items-center z-50 text-white">
          <div className="bg-black p-4 flex flex-col gap-2 border border-[#d40007]/60 rounded-lg">
            <p className="text-xs font-semibold text-green-700">
              Thank you for updating the results!
            </p>
            <p className="text-sm max-w-[400px]">
              You lost this challenge. Are you sure you want to update the
              results?
            </p>
            <div className="grid grid-cols-2 w-full gap-2">
              <button
                onClick={() => challengeLost(challenge._id)}
                className="bg-[#d40007] text-white px-4 py-2 rounded"
              >
                Yes
              </button>
              <button
                onClick={() => setLostModal(false)}
                className="bg-[#d40007] px-4 py-2 rounded"
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}
      {errorModal && (
        <div className="fixed inset-0 bg-black bg-opacity-80 flex justify-center items-center z-50 text-white">
          <div className="bg-black p-4 flex flex-col gap-2 border border-[#d40007]/60 rounded-lg">
            <p className="text-xs font-semibold text-green-700">
              Please Contact to Admin!
            </p>

            <div className="grid grid-cols-1 w-full gap-2">
              <Link
                to={`https://wa.me/916372881385?`}
                className="w-full bg-green-600 text-white py-3 px-4 rounded-lg font-semibold hover:bg-green-700 transition-colors duration-300 flex items-center justify-center gap-2"
              >
                <span className="text-white">
                  <IoLogoWhatsapp />
                </span>
                Send Message
              </Link>
              <button
                onClick={() => setErrorModal(false)}
                className="bg-[#d40007] px-4 py-2 rounded"
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

// UserInfo Component
const UserInfo = ({ user }) => {
  const { getProfilePicUrl } = useContext(StoreContext);
  return (
    <div className="flex flex-col items-center overflow-hidden">
      <div
        className={`border-2 p-1 border-[#d40007] rounded-full overflow-hidden w-fit`}
      >
        <img
          className="w-[45px] h-[45px] rounded-full"
          src={getProfilePicUrl(user?.user)}
          alt={"Avatar"}
        />
      </div>
      <span className="text-sm line-clamp-1">
        {user?.user?.fullname || user?.gameName || "Unknown"}
      </span>
    </div>
  );
};

// InfoCard Component
const InfoCard = ({ label, value }) => (
  <div className="border border-[#d40007]/70 max-[400px]:flex-col rounded-full flex items-center justify-center text-xs py-2 gap-2 ">
    <p>{label}</p>
    <div>{value}</div>
  </div>
);

// ActionCard Component
const ActionCard = ({ label, onClick }) => (
  <div
    onClick={onClick}
    className={`${
      label === "Cancel Challenge"
        ? "bg-[#d40007]"
        : label === "LOST"
        ? "bg-[#d40007]"
        : label === "ERROR"
        ? "bg-orange-700"
        : "bg-green-700"
    } py-3 rounded-full flex items-center justify-center text-sm py-1 gap-1 cursor-pointer font-semibold`}
  >
    {label === "WON" && <RiMedal2Fill />}
    {label === "LOST" && <RiEmotionSadLine />}
    {label === "ERROR" && <MdErrorOutline />}
    {label}
  </div>
);

export default LudoDetails;
