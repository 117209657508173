import axios from "axios";
import { createContext, useEffect, useState } from "react";
import {
  avatarBackground,
  avatars,
  avtarUrl,
  premiumAvatars,
} from "../assets/Avtars";
import io from "socket.io-client";
export const StoreContext = createContext(null);
const StoreContextProvider = (props) => {
  const [token, setToken] = useState("");
  const [currTab, setCurrTab] = useState("home");
  const [userData, setUserData] = useState();
  const [loader, setLoader] = useState(false);
  const [matches, setMatches] = useState([]);
  const [match, setMatch] = useState();
  // const url = 'http://localhost:4000';
  const url =`${import.meta.env.VITE_APP_API_URL}`

  const socket = io(`${url}`, {
    transports: ["websocket"],
  });
  const serverUrl = `${url}/api/v1`;
  useEffect(() => {
    if (localStorage.getItem("warpoint")) {
      const data = JSON.parse(localStorage.getItem("warpoint"));
      setToken(data?.token);
      const userfetch = async () => {
        try {
          const user = await axios.get(`${serverUrl}/user/getuserwithtoken`, {
            withCredentials: true,
          });
          if (user.data.success) {
            setUserData(user.data.user);
          }
        } catch (error) {
          console.log("Error while fetching token data", error);
        }
      };
      userfetch();
    }
  }, []);
  const getProfilePicUrl = (user) => {
    switch (user?.profilePic?.status) {
      case "none":
        return user.profilePic.none;
      case "avatar":
        return user.profilePic.avatar;
      case "premium":
        return user.profilePic.premium;
      default:
        return `https://api.dicebear.com/9.x/adventurer/svg?seed=${
          user?.fullname?.split(" ")[0] || "MidNight"
        }&backgroundColor=34d399`;
    }
  };
  const matchData = [];
  const contextValue = {
    matchData,
    token,
    setToken,
    currTab,
    setCurrTab,
    serverUrl,
    userData,
    setUserData,
    loader,
    setLoader,
    matches,
    setMatches,
    avatarBackground,
    premiumAvatars,
    avatars,
    getProfilePicUrl,
    avtarUrl,
    match,
    setMatch,
    socket,
  };
  return (
    <StoreContext.Provider value={contextValue}>
      {props.children}
    </StoreContext.Provider>
  );
};

export default StoreContextProvider;
