import React, { useContext, useEffect, useState } from "react";
import { StoreContext } from "../../context/StoreContext";
import axios from "axios";
import toast from "react-hot-toast";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { IoLogoWhatsapp } from "../../components/ReactIcons/Icons.js";
const UploadScreenShot = () => {
  const { state } = useLocation();
  const { setCurrTab, serverUrl, userData, getProfilePicUrl } =
    useContext(StoreContext);
  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const [error, setError] = useState("");
  const [challenge, setChallenge] = useState();
  const challengeId = useParams().id;
  const navigate = useNavigate();
  useEffect(() => {
    if (!userData) {
      navigate("/login");
    }
  }, []);
  useEffect(() => {
    setCurrTab("screenshot");
  }, [setCurrTab]);
  useEffect(() => {
    setChallenge(state.challenge);
  }, []);
  const uploadImage = async (file) => {
    if (challenge) {
      const isUserCreator = challenge.createdBy.user._id === userData._id;
      const imgUrlLength = isUserCreator
        ? challenge.createdBy.imgUrl.length
        : challenge.acceptedBy.imgUrl.length;

      if (imgUrlLength === 2) {
        return;
      }

      const formData = new FormData();
      formData.append("file", file);
      formData.append("upload_preset", "g8wmycyh");

      try {
        const response = await axios.post(
          "https://api.cloudinary.com/v1_1/db7pikwo4/image/upload",
          formData
        );
        setImageUrl(response.data.secure_url);
        setImage(response.data.public_id);
        await uploadImageToServer(response.data.secure_url);
      } catch (error) {
        setError("Failed to upload image. Please try again.");
        toast.error("Uploading Error!");
      }
    }
  };

  const uploadImageToServer = async (imageUrl) => {
    try {
      const response = await axios.post(
        `${serverUrl}/ludo/upload`,
        { url: imageUrl, challengeId },
        { withCredentials: true }
      );

      if (response.data.success) {
        toast.success(response.data.message || "Image uploaded.");
        setChallenge(response.data.challenge);
      } else {
        toast.error(response.data.message || "Failed to upload.");
      }
    } catch (error) {
      toast.error(
        error.response?.data?.message || error?.message || "Failed to upload"
      );
    }
  };

  //   const deleteImage = async () => {
  //     if (!image) return; // Do nothing if there's no image to delete

  //     try {
  //       await axios.post(
  //         "https://api.cloudinary.com/v1_1/db7pikwo4/image/destroy",
  //         {
  //           public_id: image,
  //           api_key: "315538888489517", // Optional if using signed requests
  //           // If you have a secret key, use a signature for security
  //           signature: "8a2a9ecd11e2db9ae1f0322a28756f570a4563ed", // Generate this using your secret key
  //         }
  //       );
  //       setImageUrl(""); // Clear the image URL
  //       setImage(null); // Clear the public ID
  //     } catch (error) {
  //       setError("Failed to delete image. Please try again.");
  //       console.error(error);
  //     }
  //   };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      uploadImage(file);
    }
  };

  return (
    <div className="py-16 px-5 flex justify-center min-h-screen">
      <div className=" flex flex-col flex-col-reverse justify-between w-full max-w-[500px]">
        {challenge && (
          <div
            className={`flex ${
              userData._id == challenge.createdBy?.user._id &&
              "flex-row-reverse"
            } gap-2 h-full text-white`}
          >
            {challenge.createdBy?.imgUrl.length > 0 && (
              <div className="border w-1/2 border-[#d40007]/60 p-2 h-full flex flex-col items-center justify-between">
                <div className="flex flex-col items-center">
                  {challenge && challenge.createdBy?.imgUrl[0] && (
                    <img
                      src={`${challenge.createdBy?.imgUrl[0]}`}
                      className="w-full"
                      alt=""
                    />
                  )}
                  {challenge && challenge.createdBy?.imgUrl[1] && (
                    <img
                      src={`${challenge.createdBy?.imgUrl[1]}`}
                      className="w-full"
                      alt=""
                    />
                  )}
                </div>
                <div className="flex flex-col items-center overflow-hidden">
                  <div className="border-2 border-red-600 p-1 rounded-full overflow-hidden w-fit">
                    {challenge.createdBy && (
                      <img
                        className="w-[45px] h-[45px] rounded-full"
                        src={getProfilePicUrl(challenge.createdBy.user)}
                        alt="Avatar"
                      />
                    )}
                  </div>
                  <span className="text-sm line-clamp-1">
                    {challenge.createdBy?.user?.fullname ||
                      challenge.createdBy?.gameName}
                  </span>
                </div>
              </div>
            )}
            {challenge.acceptedBy?.imgUrl.length > 0 && (
              <div className="border w-1/2 border-[#d40007]/60 p-2 h-full flex flex-col items-center justify-between">
                <div className="flex flex-col items-center">
                  {challenge && challenge.acceptedBy?.imgUrl[0] && (
                    <img src={`${challenge.acceptedBy?.imgUrl[0]}`} alt="" />
                  )}
                  {challenge && challenge.acceptedBy?.imgUrl[1] && (
                    <img src={`${challenge.acceptedBy?.imgUrl[1]}`} alt="" />
                  )}
                </div>
                <div className="flex flex-col items-center overflow-hidden">
                  <div className="border-2 border-red-600 p-1 rounded-full overflow-hidden w-fit">
                    {challenge.acceptedBy && (
                      <img
                        className="w-[45px] h-[45px] rounded-full"
                        src={getProfilePicUrl(challenge.acceptedBy.user)}
                        alt="Premium Avatar"
                      />
                    )}
                  </div>
                  <span className="text-sm line-clamp-1">
                    {challenge.acceptedBy?.user?.fullname ||
                      challenge.acceptedBy?.gameName}
                  </span>
                </div>
              </div>
            )}
          </div>
        )}
        <input
          type="file"
          accept="image/*"
          onChange={handleImageChange}
          className="my-4 p-2 text-white font-semibold bg-[#d40007]"
        />
        {error && <p className="text-red-600 font-semibold">{error}</p>}
        <div className="text-white flex gap-1 font-semibold text-sm">
          *{" "}
          <p>
            If your opponent submits a fake screenshot, you can view here and
            report it to the admin.
          </p>
          <Link
            to={`https://wa.me/916372881385?`}
            className="w-fit bg-green-600 text-white p-2 rounded-lg font-semibold hover:bg-green-700 transition-colors duration-300 flex items-center justify-center gap-1"
          >
            <IoLogoWhatsapp className="text-white " />
            Report
          </Link>
        </div>
        <div className="text-white flex gap-1 font-semibold text-sm">
          *{" "}
          <p>
            If you lose, don't cheat by sending fake screenshots. If caught, 50
            coins will be deducted from your wallet.
          </p>
        </div>
      </div>
    </div>
  );
};

export default UploadScreenShot;
