import React from 'react';
import { useNavigate } from 'react-router-dom';

const MenuItem = ({ text, navigateTo, onClose }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    onClose();
    navigate(navigateTo);
  };

  return (
    <li
      onClick={handleClick}
      className="group w-full overflow-hidden flex items-center justify-center cursor-pointer"
    >
      <span className="menuchar w-full flex items-center translate-y-[100%] relative">
        <div className="bg-[#d40007] duration-300 absolute h-0 right-0 w-0 group-hover:h-full group-hover:w-full"></div>
        <div className="relative pr-6">{text}</div>
      </span>
    </li>
  );
};

export default MenuItem;
