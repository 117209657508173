import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.jsx'
import './index.css'
import StoreContextProvider from "./context/StoreContext.jsx";
import { BrowserRouter} from 'react-router-dom'
import LocomotiveScroll from 'locomotive-scroll';
const locomotiveScroll = new LocomotiveScroll();

ReactDOM.createRoot(document.getElementById('root')).render(
  <StoreContextProvider> 
    <BrowserRouter>
    <App />
    </BrowserRouter>
  </StoreContextProvider>
  ,
)
