import React from "react";
import { motion } from "framer-motion";
const Avatar = ({ avatar, url, isPremium, background }) => {
  return (
    <motion.div
      whileHover={{ scale: 1.2 }}
      transition={{ type: "spring", stiffness: 300, damping: 12 }}
      className="border-2 border-[#e70000] bg-black p-1 rounded-full"
    >
      <div
        className={`flex h-[80px] w-[80px] ${
          !isPremium ? background : ""
        } rounded-full`}
      >
        <img
          className="rounded-full h-[80px] w-[80px]"
          src={isPremium ? avatar : `${url}${avatar}`}
          alt="avatar"
        />
      </div>
    </motion.div>
  );
};

export default Avatar;
