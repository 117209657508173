import React, { useRef } from "react";
import { motion, useScroll, useTransform } from "framer-motion";
import Coin from "../../components/SmallComponents/Coin/Coin";
import { useContext } from "react";
import { StoreContext } from "../../context/StoreContext";
import { Link } from "react-router-dom";

const Banner = () => {
  const { token } = useContext(StoreContext);
  const con = useRef();
  // Use Framer Motion's scroll hook to get scroll progress
  const { scrollYProgress } = useScroll({
    target: con,
    offset: ["start end", "end start"],
  });

  // Tighter parallax effect for the text elements
  const divY = useTransform(scrollYProgress, [0, 1], [-30, 30]);

  // Tighter parallax effect for the yellow square
  const squareY = useTransform(scrollYProgress, [0, 1], [-50, 50]);

  return (
    <div
      ref={con}
      className="w-full  bg-gradient-to-r from-black via-[#d40007] to-black text-white my-5 py-10 px-4 flex items-center justify-center text-center"
    >
      <div className="grid grid-cols-2 max-w-[1200px] w-full max-[650px]:grid-cols-1 max-[650px]:gap-10">
        <motion.div
          style={{ y: divY }}
          className="max-w-xl flex flex-col items-center"
        >
          <h1 className="text-2xl font-bold mb-2 md:text-3xl">Refer & Earn!</h1>
          <div className="text-lg mb-4 md:text-xl flex flex-wrap gap-2 items-center">
            <p>Get a chance to win</p>{" "}
            <span className="flex items-center gap-1">
              <Coin />
              1000+ coins.
            </span>
          </div>

          <motion.div
            whileHover={{ scale: 1.2 }}
            transition={{ type: "spring", stiffness: 300, damping: 12 }}
          >
            <Link
              to={`/profile/${token}/invite`}
              className="bg-[#d40007] text-white font-semibold py-2 px-4 rounded-lg shadow-md hover:bg-[#d40007]/60 transition duration-300"
            >
              Refer Now
            </Link>
          </motion.div>
        </motion.div>
        <motion.div
          className="relative flex items-center justify-center"
          style={{ y: squareY }}
        >
          <svg
            className="absolute z-[2]"
            height="100"
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 100 100"
            width="100"
            fill="#d40007"
            x="0"
            xmlns="http://www.w3.org/2000/svg"
            y="0"
          >
            <path d="M62.11,53.93c22.582-3.125,22.304-23.471,18.152-29.929-4.166-6.444-10.36-2.153-10.36-2.153v-4.166H30.099v4.166s-6.194-4.291-10.36,2.153c-4.152,6.458-4.43,26.804,18.152,29.929l5.236,7.777v8.249s-.944,4.597-4.833,4.986c-3.903,.389-7.791,4.028-7.791,7.374h38.997c0-3.347-3.889-6.986-7.791-7.374-3.889-.389-4.833-4.986-4.833-4.986v-8.249l5.236-7.777Zm7.388-24.818s2.833-3.097,5.111-1.347c2.292,1.75,2.292,15.86-8.999,18.138l3.889-16.791Zm-44.108-1.347c2.278-1.75,5.111,1.347,5.111,1.347l3.889,16.791c-11.291-2.278-11.291-16.388-8.999-18.138Z"></path>
          </svg>
          <motion.div
            whileHover={{ scale: 1.2 }}
            transition={{ type: "spring", stiffness: 300, damping: 12 }}
            className="relative z-[1]"
          >
            <div className="absolute w-[150px] h-[150px] bg-[#efd510] rotate-[45deg] rounded"></div>
            <div className="relative w-[150px] h-[150px] bg-[#efd510] rounded"></div>
          </motion.div>
        </motion.div>
      </div>
    </div>
  );
};

export default Banner;
