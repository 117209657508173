import React, { useState, useRef } from "react";
import { motion, useInView } from "framer-motion";

const TournamentItem = ({ img, logo, name }) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [hasEnteredView, setHasEnteredView] = useState(false);

  // Reference for the motion div to check in/out of view
  const ref = useRef(null);

  // useInView hook to trigger animation when entering view
  const isInView = useInView(ref, {
    once: false, // Set to true if you want the animation to run only once
    margin: "-10%",
  });

  // Handle image load to update the state
  const handleImageLoad = () => {
    setIsImageLoaded(true);
  };

  // When the component enters the view for the first time, set `hasEnteredView`
  if (isInView && !hasEnteredView) {
    setHasEnteredView(true);
  }

  return (
    <motion.div
      ref={ref}
      initial={{ scale: hasEnteredView ? 0.6 : 1 }}
      animate={isInView && hasEnteredView ? { scale: 1 } : { scale: 0.6 }}
      transition={{ type: "spring",stiffness: 200, damping: 12,duration: 0.3, ease: "easeOut" }}
      className="relative flex items-center justify-center overflow-hidden w-full"
    >
      <img
        className={`relative z-[1] w-full transition-opacity duration-300 ${
          isImageLoaded ? "opacity-100" : "opacity-0"
        }`}
        src={img}
        alt={`${name} img`}
        loading="lazy"
        onLoad={handleImageLoad}
      />
      {/* Loading placeholder before the image loads */}
      {!isImageLoaded && (
        <div className="absolute inset-0 z-[3] h-full w-full flex items-center justify-center bg-zinc-900 animate-pulse"></div>
      )}
      {/* Logo and Ping Animation */}
      {logo && isImageLoaded && (
        <>
          <img
            className={`absolute z-[2] ${name=='ludo'?"w-[40%]":'w-[70%]'}  text-white `}
            src={logo === "undefined" ? "" : logo}
            alt={`${name} logo`}
            loading="lazy"
          />
          <img
            className={`absolute animate-ping z-[2] ${name=='ludo'?"w-[30%]":'w-[40%]'}  text-white `}
            src={logo === "undefined" ? "" : logo}
            alt={`${name} logo`}
            loading="lazy"
          />
        </>
      )}

      
    </motion.div>
  );
};

export default TournamentItem;
