import React from "react";
import TournamentItem from "../../components/SmallComponents/TournamentItem";
import { Link } from "react-router-dom";
const Tournaments = () => {
  return (
    <div className="bg-black max-w-[1800px] h-fit max-[900px]:min-h-screen w-[100vw] p-5  grid grid-cols-3 gap-5  max-[900px]:grid-cols-1  overflow-hidden">
    
      <Link to={"ludo"}>
        <TournamentItem
          img={
            "https://res.cloudinary.com/db7pikwo4/image/upload/v1729053486/ab4qtfrsb40savvhw8ih.webp"
          }
          logo={
            "https://res.cloudinary.com/db7pikwo4/image/upload/v1728719562/zto1vwgesno1rsrek4cw.webp"
          }
          name={"ludo"}
        />
      </Link>
      <Link to={"alltournaments/bgmi"}>
        <TournamentItem
          img={
            "https://res.cloudinary.com/db7pikwo4/image/upload/v1725703082/umyf8e32tillu4aj6vmo.webp"
          }
          logo={
            "https://res.cloudinary.com/db7pikwo4/image/upload/v1725703107/shngdry7xmxi0jaxthfl.webp"
          }
          name={"bgmi"}
        />
      </Link>
      {/* <Link to={"tdm/challange"}>
          <TournamentItem img={'https://res.cloudinary.com/db7pikwo4/image/upload/v1726321664/r8uylfmjtrfmnzofajum.webp'} logo={'https://res.cloudinary.com/db7pikwo4/image/upload/v1726321571/n5yarmozo1sys0lf89ty.webp'} name={"tdmchallange"} />
        </Link> */}
      <Link to={"alltournaments/freefire"}>
        <TournamentItem
          img={
            "https://res.cloudinary.com/db7pikwo4/image/upload/v1725703187/htbcoohelwvx32kg3upa.webp"
          }
          logo={
            "https://res.cloudinary.com/db7pikwo4/image/upload/v1725703169/tezruobl0ztwoplqupwx.webp"
          }
          name={"freefire"}
        />
      </Link>
      <Link to={"alltournaments/callofduty"}>
        <TournamentItem
          img={
            "https://res.cloudinary.com/db7pikwo4/image/upload/v1725703127/keet928bapkqcga00mn8.webp"
          }
          logo={
            "https://res.cloudinary.com/db7pikwo4/image/upload/v1725703150/qzznvsgnwtaeb6upig3u.webp"
          }
          name={"callofduty"}
        />
      </Link>
      <Link to={"alltournaments/pubglite"}>
        <TournamentItem
          img={`https://res.cloudinary.com/db7pikwo4/image/upload/v1726397020/s4esilcswjypvnahsu3w.webp`}
          name={"pubglite"}
        />
      </Link>
      {/* <Link to={"alltournaments/pubg"}>
        <TournamentItem
          img={`https://res.cloudinary.com/db7pikwo4/image/upload/v1726397020/mjkzqpxrgqliov9u3cfc.webp`}
          name={"pubg"}
        />
      </Link> */}
      <Link to={"alltournaments/newstate"}>
        <TournamentItem
          img={`https://res.cloudinary.com/db7pikwo4/image/upload/v1726397020/di1xbtfgomllw1ti7jdj.webp`}
          name={"newstate"}
        />
      </Link>
    </div>
  );
};

export default Tournaments;
