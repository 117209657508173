import React, { useState } from "react";
import { motion, useAnimation } from "framer-motion";
import { useNavigate } from "react-router-dom";

const NavLogo = () => {
  const [num, setNum] = useState(0);
  const navigate = useNavigate();
  const controls = useAnimation();

  // Infinite animation for each character to scale up and down
  const hoverAnimation = {
    scale: [1, 1.2, 1],
    transition: {
      duration: 1.5,
      repeat: Infinity,
      ease: "easeInOut",
    },
  };

  return (
    <h1
      onClick={() => navigate("/")}
      onMouseEnter={() => setNum(1)}
      onMouseLeave={() => setNum(0)}
      className="uppercase flex gap-1 p-2 cursor-pointer"
    >
      {"warpoint".split("").map((char, index) => (
        <motion.span
          key={index}
          className={`circlelogo text-xs h-[20px] w-[20px] flex items-center justify-center rounded-full ${
            index % 2 === num
              ? "border border-[#d40007] bg-[#d40007] text-white"
              : "border border-[#d40007] text-white"
          }`}
          animate={hoverAnimation}
        >
          {char}
        </motion.span>
      ))}
    </h1>
  );
};

export default NavLogo;
