import {useContext,useEffect } from "react";
import Hero from "./pages/Home/Home";
import Tournaments from "./pages/Home/Tournaments";
import AvatarScroll from "./pages/Home/AvatarScroll";
import FAQ from "./pages/FAQ/FAQ";
import { StoreContext } from "./context/StoreContext";
import Banner from "./pages/Home/Banner";
const Layout = () => {
  const { setCurrTab } = useContext(StoreContext);
  useEffect(() => {
    setCurrTab("home");
    window.scrollTo({ top: 0,behavior: 'smooth' });
  }, []);
  
  return (
    <div className="relative bg-black flex flex-col items-center overflow-x-hidden">
      <Hero />
      <Tournaments />
      <AvatarScroll />
      <Banner/>
      <div className="max-[770px]:hidden"><FAQ /></div>
    </div>
  );
};

export default Layout;
