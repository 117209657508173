import React, { useState } from "react";
import Coin from "../../../components/SmallComponents/Coin/Coin";

const AcceptModal = ({ userData, acceptedAmount, setAcceptModal, setAcceptName, acceptName,challengeAccept }) => {
  const [error, setError] = useState("");

  // Handle challenge acceptance logic
  const handleAccept = () => {
    if (!acceptName) {
      setError("Enter Ludo username");
    } else {
      setError("");
      challengeAccept();
    }
  };

  // Handle close modal
  const handleClose = () => {
    setAcceptModal(false);
  };

  // If the user does not have enough balance, show insufficient balance UI
  if (userData && userData.wallet.total < acceptedAmount) {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-80 flex justify-center items-center z-50 text-white">
        <div className="bg-black p-4 flex flex-col gap-2 border border-[#d40007]/60 rounded-lg">
          <div className="flex flex-col gap-1 items-center">
            <p className="text-sm">Your wallet balance is insufficient!</p>
            <p className="text-sm">Do you want to add balance?</p>
            <div className="grid grid-cols-2 gap-2 mt-2 w-full">
              <button className="bg-green-700 py-2 w-full text-center rounded-full">
                Yes
              </button>
              <button onClick={handleClose} className="bg-[#d40007] py-2 w-full text-center rounded-full">
                No
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // If the user has enough balance, show challenge acceptance UI
  return (
    <div className="fixed inset-0 bg-black bg-opacity-80 flex justify-center items-center z-50 text-white">
      <div className="bg-black p-4 flex flex-col gap-2 border border-[#d40007]/60 rounded-lg">
        <div className="flex flex-col gap-2">
          <div className="text-sm flex gap-2">
            <div className="flex items-center gap-1">
              <Coin />
              {acceptedAmount}
            </div>{" "}
            will be deducted from your wallet.
          </div>
          <input
            type="text"
            className="w-full p-2 border border-[#d40007] rounded bg-gray-800 text-white"
            placeholder="Ludo Username"
            value={acceptName}
            onChange={(e) => setAcceptName(e.target.value)}
          />
          {error && <p className="text-xs text-red-500">{error}</p>}
          <p className="text-sm">
            Are you sure you want to accept this Challenge?
          </p>
          <div className="grid grid-cols-2 gap-2">
            <button onClick={handleAccept} className="bg-green-700 py-2 w-full text-center rounded-full">
              Yes
            </button>
            <button onClick={handleClose} className="bg-[#d40007] py-2 w-full text-center rounded-full">
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AcceptModal;
