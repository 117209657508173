import React, { useContext, useEffect, useState } from "react";
import { StoreContext } from "../../context/StoreContext";
import { toast } from "react-hot-toast";
import axios from "axios";
import Coin from "../../components/SmallComponents/Coin/Coin";
import Challenge from "./Items/Challenge";
import ChallengeModal from "./Items/ChallengeModal";
import { IoMdRefresh } from "react-icons/io";
import { FiPlus } from "react-icons/fi";
import AcceptModal from "./Items/AcceptModal";
import { useNavigate } from "react-router-dom";

const Ludo = () => {
  const { currTab, setCurrTab, serverUrl, userData, setUserData,socket } =
    useContext(StoreContext);
  const navigate = useNavigate();
  useEffect(() => {
    if (!userData) {
      navigate("/login");
    }
  }, []);
  const [name, setName] = useState("");
  const [acceptName, setAcceptName] = useState("");
  const [challenges, setChallenges] = useState([]);
  const [allChallenges, setAllChallenges] = useState([]); // Stores all challenges fetched from the server
  const [showModal, setShowModal] = useState(false); // Modal visibility state
  const [amount, setAmount] = useState("");
  const [error, setError] = useState("");
  const [accepModal, setAcceptModal] = useState(false);
  const [acceptedChallengeId, setAcceptedChallengeId] = useState("");
  const [acceptedAmount, setAcceptedAmount] = useState(0);
  const [type, setType] = useState("all");
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [cancelingId, setCancelingId] = useState("");
  const [countAcceptedChallenge, setCountAcceptedChallenge] = useState(0);
  //for socket.io
  
  useEffect(() => {
    // Listen for 'newMatchCreated' event
    socket.on("newMatchCreated", (data) => {
      console.log("New match created:", data);
      getChallenges();
    });
    socket.on("refreshToChange", (data) => {
      console.log(data.message);
      getChallenges();
    });
    // Cleanup listener on unmount
    return () => {
      socket.off("newMatchCreated");
      socket.off("refreshToChange")
    };
  }, [socket]);

  // Function to create a challenge
  const createChallenge = async () => {
    if (amount < 10 || amount % 10 !== 0) {
      setError("Amount should be a multiple of 10 and greater than 10.");
      return;
    }

    try {
      const response = await axios.post(
        `${serverUrl}/ludo/create`,
        { name, amount },
        { withCredentials: true }
      );

      if (response.data.success) {
        toast.success(
          `${response?.data?.message || "Challenge Created Successfully"}`
        );
        getChallenges(); // Fetch challenges again after creation
        setShowModal(false); // Close modal after successful creation
      } else {
        toast.error("Failed to create challenge!");
      }
    } catch (error) {
      console.log(error);
      toast.error(
        `${
          error?.response?.data?.message ||
          error?.message ||
          "Failed to create challenge!"
        }`
      );
    }
  };

  // Function to fetch challenges
  const getChallenges = async () => {
    const userId = userData?._id;
    if(!userId){
      return ;
    }
    try {
      const response = await axios.get(`${serverUrl}/ludo/get`, {
        withCredentials: true,
      });

      if (response.data.success) {
        const sortedChallenges = response.data.challenges.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        setAllChallenges(sortedChallenges); 
        setChallenges(sortedChallenges);
        setCountAcceptedChallenge(
          response.data.challenges.filter(
            (challenge) =>
              challenge.createdBy.user._id == userId &&
              challenge.status === "active" &&
              challenge.acceptedBy
          ).length
        );
      } else {
        toast.error("Failed to fetch challenges!");
      }
    } catch (error) {
      console.log(error);
      toast.error(
        `${
          error?.response?.data?.message ||
          error?.message ||
          "Failed to fetch challenges!"
        }`
      );
    }
  };
  const challengeAccept = async () => {
    try {
      const response = await axios.post(
        `${serverUrl}/ludo/accept`,
        { challengeId: acceptedChallengeId, name: acceptName },
        {
          withCredentials: true,
        }
      );
      if (response.data.success) {
        setUserData(response.data.user);
        toast.success(`${response.data?.message || "Accepted"}`);
        getChallenges();
        setAcceptModal(false);
      }
    } catch (error) {
      toast.error(
        `${error.response?.data?.message || error?.message || "Server Error!"} `
      );
    }
  };
  // Filter challenges based on type (All or My)
  useEffect(() => {
    const filteredChallenges = allChallenges.filter((challenge) => {
      if (type === "all") {
        // Exclude challenges created by the current user and ensure the status is "created"
        return (
          challenge.createdBy.user._id !== userData._id &&
          challenge.status === "created"
        );
      } else if (type === "my") {
        // Include challenges either created by or accepted by the current user
        return (
          challenge.createdBy.user._id === userData._id ||
          challenge.acceptedBy?.user?._id === userData._id
        );
      }
      return false;
    });

    // Set filtered challenges
    setChallenges(filteredChallenges);
  }, [type, allChallenges, userData]);

  const cancelChallenge = async (
    challengeId = cancelingId,
    idofuser = userData._id
  ) => {
    try {
      // API call to cancel the challenge
      const response = await axios.post(
        `${serverUrl}/ludo/cancel`,
        {
          challengeId,
          idofuser,
        },
        { withCredentials: true }
      );
      // If the challenge was canceled successfully
      if (response.data.success) {
        toast.success("Challenge canceled and deleted successfully!");
        getChallenges();
        setShowConfirmModal();
      } else {
        // Handle the case where the cancelation failed
        toast.error(
          response.data?.message || "Failed to cancel the challenge."
        );
      }
    } catch (error) {
      // Handle any errors that occur during the API request
      toast.error(error.response?.data?.message || "Server Error!");
    } finally {
      setShowConfirmModal(false);
    }
  };

  // Initial fetch of challenges when the component mounts
  useEffect(() => {
    setCurrTab("ludo");
    getChallenges();
  }, []);

  // Predefined amount boxes
  const amounts = [10, 20, 50, 100, 200, 500, 1000, 1500];

  // Handle input changes for custom amount
  const handleCustomAmount = (e) => {
    if (!/^\d+$/.test(e.target.value)) {
      setError("enter a valid number");
    }
    if (Number(e.target.value) < 10 || Number(e.target.value) % 10 !== 0) {
      setError("Amount should be a multiple of 10 and greater than 10.");
    } else {
      setError("");
    }
    if (/^\d+$/.test(e.target.value) || e.target.value == "")
      setAmount(Number(e.target.value));
  };

  return (
    <div className="min-h-screen py-16 flex justify-center bg-black">
      <div className="text-white flex flex-col gap-3 w-full mx-5 max-w-[500px]">
        <div className="w-full border border-[#d40007]/70 rounded-full grid grid-cols-2 uppercase text-white font-semibold">
          <div
            onClick={() => setType("all")}
            className={`w-full text-center text-sm py-2 ${
              type === "all" ? "bg-[#d40007]" : ""
            } rounded-full cursor-pointer`}
          >
            All Challenges
          </div>
          <div
            onClick={() => setType("my")}
            className={`w-full text-center text-sm py-2 ${
              type === "my" ? "bg-[#d40007]" : ""
            } rounded-full cursor-pointer`}
          >
            My Challenges
          </div>
        </div>
        

        <div className="flex gap-2 items-center">
          <h2 className="w-full">
            <span className="bg-[#d40007] py-1 px-3 rounded-full">
              {challenges.length}
            </span>{" "}
            Active Challenges
          </h2>
          <div className="grid grid-cols-2 gap-2 w-full">
            <div
              className="bg-[#d40007] font-semibold rounded-full py-2 cursor-pointer flex items-center justify-center gap-1"
              onClick={() => setShowModal(true)}
            >
              <FiPlus />
              Create
            </div>
            <div
              className="bg-[#d40007] font-semibold rounded-full py-2 cursor-pointer flex items-center justify-center gap-1"
              onClick={() => getChallenges()}
            >
              <IoMdRefresh />
              Refresh
            </div>
          </div>
        </div>
        {countAcceptedChallenge > 0 &&type!="my" && (
          <div onClick={() => setType("my")} className="flex items-center w-full justify-between gap-3 cursor-pointer">
            <p className="capitalize text-xs text-green-500 font-semibold w-full text-nowrap">
              some one accepted your challenge
            </p>
            <div className="w-full border border-[#d40007]/50"></div>
            <div className="text-white text-xs  w-fit text-nowrap rounded-full px-2 capitalize">
              go to my challenges
            </div>
          </div>
        )}
        <div className="flex flex-col gap-1">
          {challenges.map((challenge, index) => (
            <Challenge
              key={index}
              getChallenges={getChallenges}
              challenge={challenge}
              setAcceptModal={setAcceptModal}
              setAcceptedChallengeId={setAcceptedChallengeId}
              setAcceptedAmount={setAcceptedAmount}
              setShowConfirmModal={setShowConfirmModal}
              cancelChallenge={cancelChallenge}
              setCancelingId={setCancelingId}
            />
          ))}
        </div>
      </div>
      {accepModal && (
        <AcceptModal
          userData={userData}
          acceptedAmount={acceptedAmount}
          setAcceptModal={setAcceptModal}
          setAcceptName={setAcceptName}
          acceptName={acceptName}
          challengeAccept={challengeAccept}
        />
      )}
      <ChallengeModal
        showModal={showModal}
        setShowModal={setShowModal}
        name={name}
        setName={setName}
        amounts={amounts}
        amount={amount}
        setAmount={setAmount}
        error={error}
        handleCustomAmount={handleCustomAmount}
        createChallenge={createChallenge}
      />
      {showConfirmModal && (
        <div className="fixed inset-0 bg-black bg-opacity-80 flex justify-center items-center z-50 text-white">
          <div className="bg-black p-4 flex flex-col gap-2 border border-[#d40007]/60 rounded-lg">
            <p className=" text-sm">Do you want to cancel the match?</p>
            <p className="text-sm max-w-[400px]">
              Participants will receive refunds and the challenge will be
              deleted.
            </p>
            <div className="grid grid-cols-2 w-full gap-2">
              <button
                onClick={() => cancelChallenge(cancelingId, userData._id)}
                className="bg-[#d40007] text-white px-4 py-2 rounded"
              >
                Yes, Cancel
              </button>
              <button
                onClick={() => setShowConfirmModal(false)}
                className="bg-[#d40007] px-4 py-2 rounded"
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Ludo;
