import React, { useContext, useMemo } from "react";
import {
  AiOutlineHome,
  IoWalletOutline,
  LiaUserSecretSolid,
  MdLeaderboard,
  TiUser,
} from "./ReactIcons/Icons.js";
import { StoreContext } from "../context/StoreContext";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

const BottomNavBar = () => {
  const { currTab, token } = useContext(StoreContext);
  const navigate = useNavigate();

  // Memoizing the translateX value based on currTab
  const translateX = useMemo(() => {
    switch (currTab) {
      case "avatars":
        return "0%";
      case "leaderboard":
        return "20%";
      case "home":
        return "40%";
      case "balance":
        return "60%";
      case "userprofile":
        return "80%";
      default:
        return "0%";
    }
  }, [currTab]);

  return (
    <div className="fixed bottom-0 m-1 overflow-hidden rounded-full left-0 right-0 bg-[#d40007] text-white shadow-md md:hidden z-[997]">
      <div className="grid grid-cols-5 justify-around items-center h-fit relative">
        {(currTab === "avatars" ||
          currTab === "leaderboard" ||
          currTab === "home" ||
          currTab === "balance" ||
          currTab === "userprofile") && (
          <motion.div
            className="p-1  absolute  z-[1] w-1/5 h-full"
            initial={{ left: "0%" }}
            animate={{ left: translateX }}
            transition={{
              type: "spring",
              stiffness: 180,   
              damping: 12,      
              mass: 0.8,       
            }}
          >
            <div className="bg-black/20 h-full w-full rounded-full"></div>
          </motion.div>
        )}

        <button
          onClick={() => navigate(`/profile/${token}/avatars`)}
          className="relative z-[2] flex flex-col items-center justify-center p-3"
        >
          <LiaUserSecretSolid className="h-4 w-4" />
          <span className="text-xs">Avatar</span>
        </button>

        <button
          onClick={() => navigate("/leaderboard")}
          className="relative z-[2] flex flex-col items-center justify-center p-3"
        >
          <MdLeaderboard className="h-4 w-4" />
          <span className="text-xs">Rank</span>
        </button>

        <button
          onClick={() => navigate("/")}
          className="relative z-[2] flex flex-col items-center justify-center p-3"
        >
          <AiOutlineHome className="h-4 w-4" />
          <span className="text-xs">Home</span>
        </button>

        <button
          onClick={() => navigate(`/profile/${token}/wallet`)}
          className="relative z-[2] flex flex-col items-center justify-center p-3"
        >
          <IoWalletOutline className="h-4 w-4" />
          <span className="text-xs">Wallet</span>
        </button>

        <button
          onClick={() => navigate(`/profile/${token}`)}
          className="relative z-[2] flex flex-col items-center justify-center p-3"
        >
          <TiUser className="h-4 w-4" />
          <span className="text-xs">Profile</span>
        </button>
      </div>
    </div>
  );
};

export default BottomNavBar;
