import React from "react";

const Rules = () => {
  return (
    <div className="bg-black flex flex-col gap-2 text-white pt-4 rounded-lg shadow-md mb-4">
      <h2 className="text-lg font-semibold">Game Rules & Instructions</h2>

      <div className="flex flex-col">
        <span className="font-semibold text-red-500">
          1. Winning Procedure:
        </span>
        <p className="text-sm pl-5">
          If you win, please take a screenshot of the winning page from the Ludo
          King app.
        </p>
      </div>
      <div className="flex flex-col">
        <span className="font-semibold text-red-500">
          2. Upload Winning Screenshot:
        </span>
        <p className="text-sm pl-5">
          Click the WON button to upload your winning screenshot.
        </p>
      </div>
      <div className="flex flex-col">
        <span className="font-semibold text-red-500">
          3. Loss Notification:
        </span>
        <p className="text-sm pl-5">
          If you lose, click on the Lost button. If you do not click Lost, 20
          coins will be deducted from your wallet.
        </p>
      </div>
      <div className="flex flex-col">
        <span className="font-semibold text-red-500">4. Timely Updates:</span>
        <p className="text-sm pl-5">
          Both players must update the result within 1 hour after the room code
          is updated.
        </p>
      </div>
      <div className="flex flex-col">
        <span className="font-semibold text-red-500">
          5. Opponent's Responsibility:
        </span>
        <p className="text-sm pl-5">
          If any opponent fails to upload the result, winning amount will get
          credited to the winner wallet within 1 hour after uploading result.
        </p>
      </div>
      <div className="flex flex-col">
        <span className="font-semibold text-red-500">
          6. Challenge Cancellation:
        </span>
        <p className="text-sm pl-5">
          After the room code is updated, no one can cancel the challenge.
        </p>
      </div>
      <div className="flex flex-col">
        <span className="font-semibold text-red-500">7. Cheating Policy:</span>
        <p className="text-sm pl-5">
          Do not attempt any form of cheating. For your safety, please record
          the match.
        </p>
      </div>
      <div className="flex flex-col">
        <span className="font-semibold text-red-500">8. Contact Admin:</span>
        <p className="text-sm pl-5">
          If you encounter any issues, please contact the admin for assistance.
        </p>
      </div>
      <div className="flex flex-col">
        <span className="font-semibold text-red-500">9. Room ID Update:</span>
        <p className="text-sm pl-5">
          The challenge creator must update the room ID within 15 minutes.
          Failure to do so will result in a 20 coin deduction and cancellation
          of the challenge, with a full refund for the opponent.
        </p>
      </div>
      <div className="flex flex-col">
        <span className="font-semibold text-red-500">10. Game Mode:</span>
        <p className="text-sm pl-5">
          Only the Classic Mode is allowed. 5-6 player modes are not permitted.
        </p>
      </div>
      <div className="flex flex-col">
        <span className="font-semibold text-red-500">11. Double-Check:</span>
        <p className="text-sm pl-5">
          {" "}
          Please double-check everything and play carefully.
        </p>
      </div>
    </div>
  );
};

export default Rules;
